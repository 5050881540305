@use '@angular/material' as mat;
@import "../app-theme.scss";

.dashboard-title-section {
    padding: 2rem 0 0 0;
    background-color: mat.get-color-from-palette($app-theme, 350);
}

.dashboard-section {
    margin-bottom: 10rem;
}

.dashboard-content {
    margin: 2rem 0 0 0;
}

.dashboard-content_table {
    margin: 2rem 0;
}

.infobox_dashboard {
    padding: 1.5rem 2rem;
    position: relative;

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.infobox-text_small {
    font-size: 1rem;
    line-height: 1.5rem;
}

.ddp-dashboard {
    .mat-cell,
    .mat-header-cell,
    .dashboard-mobile-label {
        font-size: 1.125rem !important;
        line-height: 1.75rem !important;
    }

    .mat-cell:not(:last-of-type) {
        padding-top: 0.3125rem !important;
    }

    .mat-column-name {
        flex: 0 0 20%;
    }

    .mat-column-status {
        flex: 0 0 15%;
    }

    .dashboard-mobile-label {
        color: rgba(0, 0, 0, 0.54);
    }

    .mat-row {
        align-items: flex-start !important;
        padding: 1.2rem 0 !important;
    }

    .dashboard-status-container__img {
        height: 25px !important;
        width: 25px !important;
        padding: 0 8px 0 0;
    }

    .Link {
        color: mat.get-color-from-palette($app-theme, 200);
        white-space: normal;
    }

    @media (max-width: 650px) {
        .mat-column-actions {
            .dashboard-mobile-label {
                line-height: 1.125rem !important;
            }
        }
    }
}

.dashboard-activity-button {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-family: inherit;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
}

.dashboard-activity-button:hover {
    color: mat.get-color-from-palette($app-theme, 650) !important;
}

.dashboard-section {
    margin-top: 3rem;

    .dashboard-panel-header {
        padding: 0.3rem 1.5rem;
        $background-color: rgba(0, 0, 0, 0.04);

        background-color: $background-color;

        &.mat-expanded:focus,
        &.mat-expanded:hover {
            background-color: $background-color !important;
        }

        .dashboard-panel-title {
            font-size: 1.375rem;
        }

        .dashboard-panel-description {
            font-size: 1.125rem;
        }
    }

    .dashboard-panel {
        margin-top: 1rem;
        box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 20%) !important;
    }
}

