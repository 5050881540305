@use '@angular/material' as mat;
@import "../app-theme.scss";

.button {
    min-width: 8.5rem;
    padding: 0.625rem 1.25rem;
    font-family: inherit;
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.button,
.button:active,
.button:hover,
.button:focus {
    text-decoration: none;
}

.button_small {
    font-size: 1.0625rem;
    line-height: 1.375rem;
}

.button_medium {
    padding: 0.625rem 1.25rem;
    font-size: 1.5rem;
    line-height: 1.9375rem;
}

.button_big {
    padding: 1.25rem 2.5rem;
    font-size: 1.625rem;
    line-height: 2.0625rem;
}

.button_primary {
    background-color: mat.get-color-from-palette($app-theme, 250);
    border: 2px solid mat.get-color-from-palette($app-theme, 50);
    color: mat.get-color-from-palette($app-theme, 100);
}

.button_primary:disabled {
    background-color: mat.get-color-from-palette($app-theme, 600);
}

.button_secondary {
    background-color: mat.get-color-from-palette($app-theme, 50);
    color: mat.get-color-from-palette($app-theme, 200);
    border: 2px solid mat.get-color-from-palette($app-theme, 200);
}

.button_secondary:disabled {
    background-color: mat.get-color-from-palette($app-theme, 50);
    color: mat.get-color-from-palette($app-theme, 650);
    border: 2px solid mat.get-color-from-palette($app-theme, 650);
}

.button_primary:hover:not([disabled]),
.button_primary:hover:enabled {
    background-color: mat.get-color-from-palette($app-theme, 300);
}

.button_secondary:hover:not([disabled]),
.button_secondary:hover:enabled {
    background-color: mat.get-color-from-palette($app-theme, 200);
    color: mat.get-color-from-palette($app-theme, 100);
}

.button_new-item {
    font-size: 1.25rem;
}

.simple-button,
.SimpleButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 200);
    text-decoration: none;
    font-size: 1.0625rem;
    line-height: 1.375rem;
    font-family: inherit;
    white-space: nowrap;

    ddp-language-selector & {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }

    }
}

.simple-button:hover,
.SimpleButton:hover {
    color: mat.get-color-from-palette($app-theme, 650);
    text-decoration: underline;
}

.simple-button:focus,
.SimpleButton:focus {
    text-decoration: underline;
}

.panel-opened {

    .simple-button,
    .SimpleButton:not(.language-selector-btn) {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
}
