@use '@angular/material' as mat;
@import 'app-theme.scss';
@import './styles/activity.scss';
@import './styles/buttons.scss';
@import './styles/dashboard.scss';
@import './styles/stay-informed.scss';
@import './styles/password.scss';
@import './styles/loved-one-thank-you.scss';
@import './styles/age-up-thank-you.scss';
@import './styles/session-expired.scss';
@import './styles/dialogs.scss';
@import './styles/static-page.scss';
@import './styles/family-history-thank-you.scss';
@import './styles/variables.scss';
@import './styles/family-history.scss';

$custom-typography: mat.define-typography-config($font-family: 'Source Sans Pro');

@include mat.core($custom-typography);

html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-size: 16px;
    color: mat.get-color-from-palette($app-theme, 200);
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    overflow-y: scroll;
}

@media only screen and (max-width: 1024px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 12px;
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 200);
}

a:focus {
    text-decoration: underline;
}

ul {
    margin: 0;
}

.emailColor {
    color: blue;
}

ol li,
ul li,
p {
    font-size: 1.375rem;
    line-height: 2.25rem;
}

h1,
h2,
h3,
h4 {
    font-family: 'Source Serif Pro', serif;
}

h1 {
    font-size: 2.25rem;
    line-height: 3.5rem;
}

h2 {
    font-size: 2.625rem;
    line-height: 2.875rem;
}

h2.big {
    font-size: 3rem;
    line-height: 4.25rem;
}

h3 {
    font-size: 1.875rem;
    line-height: 3.5rem;
}

h4 {
    font-size: 2rem;
    line-height: 2.375rem;
}

.wrapper {
    height: 100vh;
    margin-bottom: -20rem; // footer's height
    padding-bottom: 20rem; // footer's height
    padding-top: 6.25rem; // header's height
    box-sizing: border-box;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
}

.section {
    width: 100%;
}

.content {
    margin: 0 auto;
    padding: 0 1.5625rem;
}

.content_wide {
    max-width: $content-wide-width;
}

.content_medium {
    max-width: $content-medium-width;
}

.content_tight {
    max-width: $content-tight-width;
}

.bold {
    font-weight: 600;
}

.normal {
    font-weight: 400;
}

.light {
    font-weight: 300;
}

.italic {
    font-style: italic;
}

.big {
    font-size: 1.75rem;
}

.underline {
    text-decoration: underline;
}

.section-title {
    text-align: center;
    margin: 4.375rem 0;
}


.no-margin {
    margin: 0;
}

.only-margin-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

.only-margin-top {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
}

.extra-margin-top {
    margin-top: 5rem;
}

.extra-margin-bottom {
    margin-bottom: 5rem;
}

.black {
    color: mat.get-color-from-palette($app-theme, 200);
}

.image {
    max-width: 100%;
    height: auto;
    width: inherit;
}

.secondary-text {
    color: mat.get-color-from-palette($app-theme, 700);
}

.Link {
    color: mat.get-color-from-palette($app-theme, 250);
    text-decoration: none;
    white-space: nowrap;
}

.Link:hover {
    text-decoration: underline;
}

.Link:active {
    color: mat.get-color-from-palette($app-theme, 300);
    text-decoration: underline;
}

.close {
    cursor: pointer;
}

.close:hover {
    color: mat.get-color-from-palette($app-theme, 650);
}

$tooltip-icon-size: 24px;
.tooltip {
    display: inline-block;
    vertical-align: middle;
    height: $tooltip-icon-size;
    margin-left: 3px;

    &__icon {
        height: $tooltip-icon-size;
        width: $tooltip-icon-size;
    }

    &__box {
        font-size: 1rem;
    }
}

// language selector
ddp-language-selector {
    .ddp-globe {
        stroke: #000000;
        fill: transparent;
        vertical-align: middle;
        margin-right: 0.2rem;
    }
}
