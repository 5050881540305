@use '@angular/material' as mat;
@import '../app-theme.scss';

.age-up-email-section {
    flex-grow: 1;
    padding: 4rem 0;
    background-color: mat.get-color-from-palette($app-theme, 850);
}

.age-up-email-title {
    text-align: center;
}

.age-up-email-image,
.age-up-email-button {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
}